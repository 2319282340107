import { keys } from './keys';

const values = {
  [keys[0]]: 0,
  [keys[1]]: 600,
  [keys[2]]: 960,
  [keys[3]]: 1280,
  [keys[4]]: 1920,
};

export { values };

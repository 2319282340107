import { Typography } from './Typography';

const body1 = new Typography(
  '"Roboto", "Helvetica", "Arial", sans-serif',
  400,
  '1rem',
  1.5,
  '0.00938em',
);

export { body1 };
